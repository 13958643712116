var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{staticClass:"relative transition duration-200 border-2 tap-color-none",class:{
    'border-transparent': !_vm.selected,
    'border-primary-500 shadow-lg': _vm.selected,
  },attrs:{"tag":"button","content":""},on:{"click":function($event){!_vm.selected ? _vm.onClick() : null}}},[_c('button',{staticClass:"relative w-full block focus:outline-none",on:{"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}}},[_c('div',{staticClass:"flex justify-between w-full"},[_c('div',{staticClass:"text-xl font-bold text-primary-500"},[_vm._v(" "+_vm._s(_vm.contract.name)+" ")]),_c('div',{staticClass:"text-gray-800"},[_c('div',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$tc('starting_at', _vm.$n(_vm.contract.price.amount, 'currency'))))]),_c('div',{staticClass:"text-xs text-right"},[_vm._v(_vm._s(_vm.$t(_vm.minPriceAccountingCycle(_vm.contract), { n: _vm.contract.price.accountingCycle })))])])]),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"text-left text-gray-800"},[_vm._v(_vm._s(_vm.contract.shortDescription))])])]),_c('form',{staticClass:"px-5 -mx-5 overflow-y-hidden transition-all duration-200",class:{
      'h-0 opacity-0': !_vm.selected,
    },style:({
      height: _vm.selected && _vm.selectedContractPackage.contractTemplates.length === 1
        ? '24.05rem' : _vm.selected && _vm.selectedContractPackage.contractTemplates.length > 1
        ? '28rem' : '0',
    }),on:{"submit":function($event){$event.preventDefault();return _vm.$emit('continue')}}},[_c('div',{staticClass:"w-full border-t-2 border-gray-200"}),(_vm.selectedContractPackage)?_c('div',{staticClass:"pt-4 w-2/3"},[(_vm.selectedContractPackage.contractTemplates.length > 1)?_c('SelectField',{attrs:{"floating":"","label":_vm.$t('contract'),"required":""},on:{"input":function ($event) { return _vm.$store.commit('selectContractTemplate', $event); }},model:{value:(_vm.selectedContractIdModel),callback:function ($$v) {_vm.selectedContractIdModel=$$v},expression:"selectedContractIdModel"}},_vm._l((_vm.selectedContractPackage.contractTemplates),function(contractTemplate){return _c('option',{key:contractTemplate.id,domProps:{"value":contractTemplate.id}},[_vm._v(" "+_vm._s(_vm.contractRenewalString(contractTemplate.contractRenewals[0]))+" ")])}),0):_vm._e()],1):_vm._e(),_c('div',[_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"font-bold text-gray-800"},[_vm._v(_vm._s(_vm.$t('modules')))]),_c('div',{staticClass:"h-8 flex items-center"},[(_vm.inclusiveModules && _vm.exclusiveModules
              && _vm.inclusiveModules.length > 0 && _vm.exclusiveModules.length > 0)?_c('FilterButtons',{attrs:{"items":_vm.filterButtonItems},model:{value:(_vm.moduleMode),callback:function ($$v) {_vm.moduleMode=$$v},expression:"moduleMode"}}):_vm._e()],1)]),_c('div',[_c('ModulesScroller',{attrs:{"modules":_vm.modules || [],"accounting-cycle":_vm.accountingCycle,"accounting-cycle-unit":_vm.selectedContractRenewal && _vm.selectedContractRenewal.accountingCycle || 0,"mode":_vm.moduleMode},on:{"select":_vm.onModuleSelect}})],1),(_vm.selectedContractRenewal)?_c('div',[_c('div',{staticClass:"pt-1 border-t-2 border-b-2 border-gray-200"}),_c('div',{staticClass:"flex justify-end items-end mt-5 text-gray-800"},[_c('span',{staticClass:"leading-tight mr-3"},[_vm._v(" "+_vm._s(_vm.$t(("sumIncludingTaxesObj." + (_vm.accountingCycle.replace('cycles.', ''))), { n: _vm.selectedContractRenewal.accountingCycle }))+": ")]),_c('span',{staticClass:"font-bold text-xl leading-none"},[_vm._v(" "+_vm._s(_vm.$n(_vm.overallPrice, 'currency'))+" ")])])]):_vm._e()]),_c('div',{staticClass:"w-full flex mt-12"},[_c('div',{staticClass:"flex flex-1 mr-2"}),_c('Button',{staticClass:"flex flex-1 ml-2",attrs:{"primary":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('continue'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }