<template>
  <div class="flex overflow-x-auto pt-3 pb-4 mb-4 -mx-5 px-3 hide-scrollbar">
    <template v-for="(extraModule) in modules">
      <button
        v-if="showItem(extraModule.price)"
        :key="extraModule.id"
        class="flex flex-shrink-0 w-1/5 mx-3 h-25 focus:outline-none
        border-2 border-transparent transition-all duration-150"
        :class="{
          'border-secondary-teal-light bg-secondary-teal-light shadow': selectedModules.includes(extraModule.id) || extraModule.price === 0,
          'shadow': !selectedModules.includes(extraModule.id),
        }"
        type="button"
        @click.stop="onSelect(extraModule.id, extraModule.price)"
      >
        <div class="w-full px-1 py-2 text-gray-800">
          <div>
            {{ extraModule.contractProperties.name }}
          </div>
          <div class="font-bold">
            {{ $n(extraModule.price, 'currency') }}
          </div>
          <div class="text-xs leading-none">
            {{ $tc(accountingCycle, accountingCycleUnit) }}
          </div>
        </div>
      </button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    modules: {
      type: Array,
      required: true,
    },
    accountingCycle: {
      type: String,
      required: true,
    },
    accountingCycleUnit: {
      type: Number,
      required: true,
    },
    mode: {
      type: String,
      default: 'all', // 'all' || 'inclusive' || 'bookable'
    },
  },

  computed: {
    selectedModules() {
      return this.$store.state.contract.selectedModules;
    },
  },

  methods: {
    showItem(price) {
      if (this.mode === 'all') return true;
      if (this.mode === 'bookable' && price > 0) return true;
      if (this.mode === 'inclusive' && price === 0) return true;
      return false;
    },
    onSelect(id, price) {
      if (price > 0) {
        this.$emit('select', id);
      }
    },
  },
};
</script>
