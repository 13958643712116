<template>
  <div>
    <div v-if="loading && contracts.length === 0" class="py-6 flex items-center justify-center">
      <LoadingCircle />
    </div>
    <template v-for="(contract, index) in contracts">
      <ContractCard
        v-if="contract.contractTemplates.length > 0"
        class="mb-4"
        :key="contract.id"
        :contract="contract"
        :selected="selected === contract.id"
        @click="onClick(index)"
        @module-select="onModuleSelect"
        @continue="onContinue(index)"
        @unselect="onUnselect"
      />
    </template>
  </div>
</template>

<script>
import LoadingCircle from '../../../components/LoadingCircle.vue';
import ContractCard from '../../../components/Contracts/Card.vue';

export default {
  components: {
    ContractCard,
    LoadingCircle,
  },

  computed: {
    selected() {
      return this.$store.state.contract.selectedContractPackage?.id;
    },
    contracts() {
      return this.$store.state.contract.contractPackages;
    },
    loading() {
      return this.$store.state.contract.loading;
    },
  },

  methods: {
    onClick(index) {
      this.$store.commit('selectPackage', index);
    },
    onUnselect() {
      this.$store.commit('unselectPackage');
    },
    onModuleSelect(id) {
      this.$store.commit('selectContractModule', id);
    },
    onContinue() {
      // const selectedModules = [];
      // this.contracts[index].modules.forEach((moduleObj) => {
      //   if (moduleObj.selected) selectedModules.push(moduleObj.id);
      // });
      this.$store.commit('updateContractForm', {
        selectedContractTemplate: this.$store.state.contract.selectedContract.id,
        selectedModules: this.$store.state.contract.selectedModules,
      });
      this.$router.push('flat-rates');
    },
  },
};
</script>
