<template>
  <Card
    tag="button"
    class="relative transition duration-200 border-2 tap-color-none"
    :class="{
      'border-transparent': !selected,
      'border-primary-500 shadow-lg': selected,
    }"
    content
    @click="!selected ? onClick() : null"
  >
    <button class="relative w-full block focus:outline-none" @click.stop="onClick">
      <div class="flex justify-between w-full">
        <div class="text-xl font-bold text-primary-500">
          {{ contract.name }}
        </div>
        <div class="text-gray-800">
          <div class="text-right">{{ $tc('starting_at', $n(contract.price.amount, 'currency')) }}</div>
          <div class="text-xs text-right">{{ $t(minPriceAccountingCycle(contract), { n: contract.price.accountingCycle }) }}</div>
        </div>
      </div>
      <div class="mb-4">
        <div class="text-left text-gray-800">{{ contract.shortDescription }}</div>
      </div>
      <!-- <div class="absolute bottom-0 right-0 -mb-3">
        <Icon name="information-outline" class="text-gray-800" />
      </div> -->
    </button>
    <form
      class="px-5 -mx-5 overflow-y-hidden transition-all duration-200"
      :class="{
        'h-0 opacity-0': !selected,
      }"
      :style="{
        height: selected && selectedContractPackage.contractTemplates.length === 1
          ? '24.05rem' : selected && selectedContractPackage.contractTemplates.length > 1
          ? '28rem' : '0',
      }"
      @submit.prevent="$emit('continue')"
    >
      <div class="w-full border-t-2 border-gray-200" />
      <div v-if="selectedContractPackage" class="pt-4 w-2/3">
        <SelectField
          v-if="selectedContractPackage.contractTemplates.length > 1"
          v-model="selectedContractIdModel"
          floating
          :label="$t('contract')"
          required
          @input="($event) => $store.commit('selectContractTemplate', $event)"
        >
          <option
            v-for="contractTemplate in selectedContractPackage.contractTemplates"
            :key="contractTemplate.id"
            :value="contractTemplate.id"
          >
            {{ contractRenewalString(contractTemplate.contractRenewals[0]) }}
          </option>
        </SelectField>
        <!-- <SelectField
          v-model="selectedContractRenewalModel"
          floating
          :label="$t('paymentCycle')"
          required
          @input="onRenewalChange"
        >
          <option
            v-for="contractRenewal in selectedContract.contractRenewals"
            :key="contractRenewal.id"
            :value="contractRenewal.id"
          >
            {{ contractRenewalString(contractRenewal) }}
          </option>
        </SelectField> -->
      </div>
      <div>
        <div class="flex justify-between items-center">
          <div class="font-bold text-gray-800">{{ $t('modules') }}</div>
          <div class="h-8 flex items-center">
            <FilterButtons
              v-if="inclusiveModules && exclusiveModules
                && inclusiveModules.length > 0 && exclusiveModules.length > 0"
              v-model="moduleMode"
              :items="filterButtonItems"
            />
          </div>
        </div>
        <div>
          <ModulesScroller
            :modules="modules || []"
            :accounting-cycle="accountingCycle"
            :accounting-cycle-unit="selectedContractRenewal && selectedContractRenewal.accountingCycle || 0"
            :mode="moduleMode"
            @select="onModuleSelect"
          />
        </div>
        <div v-if="selectedContractRenewal">
          <div class="pt-1 border-t-2 border-b-2 border-gray-200" />
          <div class="flex justify-end items-end mt-5 text-gray-800">
            <span class="leading-tight mr-3">
              {{ $t(`sumIncludingTaxesObj.${accountingCycle.replace('cycles.', '')}`, { n: selectedContractRenewal.accountingCycle }) }}:
            </span>
            <span class="font-bold text-xl leading-none">
              {{ $n(overallPrice, 'currency') }}
            </span>
          </div>
        </div>
      </div>
      <div class="w-full flex mt-12">
        <!-- <Button
          class="flex flex-1 mr-2"
          to="../home"
        >
          {{ $t('back') }}
        </Button> -->
        <div class="flex flex-1 mr-2"></div>
        <Button
          class="flex flex-1 ml-2"
          primary
          type="submit"
        >
          {{ $t('continue') }}
        </Button>
      </div>
    </form>
  </Card>
</template>

<script>
import { cycleUnitsToString, accountingCycleToString } from '../../../utils';
import ModulesScroller from './ModulesScroller.vue';
import FilterButtons from '../FilterButtons.vue';

export default {
  components: {
    ModulesScroller,
    FilterButtons,
  },

  props: {
    contract: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      paymentCycle: null,
      selectedContractIdModel: null,
      selectedContractRenewalModel: null,
      moduleMode: 'all',
    };
  },

  computed: {
    paymentCycles() {
      return ['monthly', 'yearly'];
    },
    overallPrice() {
      let price = this.selectedContractRenewal.amount;
      if (this.modules) {
        this.modules.forEach((moduleObj) => {
          if (this.selectedModules.includes(moduleObj.id)) {
            price += moduleObj.price;
          }
        });
      }
      return Number(price.toFixed(2));
    },
    selectedModules() {
      return this.$store.state.contract.selectedModules;
    },
    selectedContractPackage() {
      return this.$store.state.contract.selectedContractPackage;
    },
    selectedContractId() {
      return this.$store.state.contract.selectedContract?.id;
    },
    selectedContract() {
      return this.$store.state.contract.selectedContract;
    },
    selectedContractRenewal() {
      return this.$store.state.contract.selectedContractRenewal;
    },
    modules() {
      return this.$store.state.contract.selectedContract?.properties;
    },
    inclusiveModules() {
      return this.$store.state.contract.selectedContract?.properties.filter((moduleObj) => moduleObj.price === 0);
    },
    exclusiveModules() {
      return this.$store.state.contract.selectedContract?.properties.filter((moduleObj) => moduleObj.price > 0);
    },
    accountingCycle() {
      if (this.selectedContractRenewal) {
        return accountingCycleToString(
          this.selectedContractRenewal.accountingCycle,
          this.selectedContractRenewal.accountingCycleUnit,
          'cycles',
        );
      }
      return '';
    },
    filterButtonItems() {
      return [
        { label: this.$t('all'), value: 'all' },
        { label: this.$t('inclusive'), value: 'inclusive' },
        { label: this.$t('bookable'), value: 'bookable' },
      ];
    },
  },

  watch: {
    paymentCycle(newVal) {
      this.$store.commit('updateContractForm', {
        paymentCycle: newVal,
      });
    },
    selectedContractId: {
      immediate: true,
      handler(newVal) {
        this.$store.state.contract.selectedModules = [];
        this.selectedContractIdModel = newVal;
      },
    },
    selectedContractRenewal: {
      immediate: true,
      handler(newVal) {
        if (newVal) this.selectedContractRenewalModel = newVal.id;
      },
    },
  },

  created() {
    this.paymentCycle = this.$store.state.contract.newContract.paymentCycle;
  },

  methods: {
    onModuleSelect(id) {
      this.$emit('module-select', id);
    },
    onClick() {
      if (this.selected) this.$emit('unselect');
      else this.$emit('click');
    },
    onRenewalChange(id) {
      this.$store.commit('setContractRenewalById', Number(id));
    },
    contractRenewalString(contractRenewal) {
      const duration = this.$tc(cycleUnitsToString(contractRenewal.durationUnit, 'durations'), contractRenewal.duration);
      const accountingCycle = this.$t(accountingCycleToString(
        contractRenewal.accountingCycle,
        contractRenewal.accountingCycleUnit,
        'accountingCycles',
      ), {
        n: contractRenewal.accountingCycle,
        amount: this.$n(contractRenewal.amount, 'currency'),
      });
      return `${duration} / ${accountingCycle}`;
    },
    minPriceAccountingCycle(contract) {
      return accountingCycleToString(
        contract.price.accountingCycle,
        contract.price.accountingCycleUnit,
        'includingTaxesObj',
      );
    },
  },
};
</script>
