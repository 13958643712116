<template>
  <div>
    <button
      v-for="item in items"
      :key="item.value"
      class="inline-flex px-2 text-center py-1 text-xs focus:outline-none focus:shadow-outline
      transition duration-200"
      :class="{
        'bg-gray-800 text-white': value === item.value,
        'bg-transparent text-gray-800': value !== item.value,
        'px-4': item.label.length <= 4,
      }"
      type="button"
      @click.stop="$emit('input', item.value)"
    >
      {{ item.label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
};
</script>
